import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import HybridBondsSelectorTable from './subComponents/HybridBondsSelectorTable.component';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

async function positionLoadConfigs() {
  const response = await axios.get(BASE_URL + 'position_update-load_configs', {
    withCredentials: true,
  });
  return response.data;
}

export default function HybridBondsSelector() {
  const [potentialHybridBondsData, setPotentialHybridBondsData] =
    useState<any>(null);

  const { isPending, error, data } = useQuery({
    queryKey: ['positionLoadConfigs'],
    queryFn: positionLoadConfigs,
  });

  useEffect(() => {
    axios
      .get(BASE_URL + 'get_all_potential_hybrid_bonds', {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          setPotentialHybridBondsData(response.data);
        } else {
          setPotentialHybridBondsData('error');
        }
      });
  }, []);

  if (potentialHybridBondsData === null) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Potential Hybrid Bonds Positions Data',
          'This may take a few seconds...',
        ]}
      />
    );
  } else if (potentialHybridBondsData === 'error') {
    return <NoDataMessage message={'Error Retrieving Data'} />;
  } else {
    return (
      <Grid container spacing={2} style={{ padding: 8 }}>
        <HybridBondsSelectorTable originalData={potentialHybridBondsData} />
      </Grid>
    );
  }
}
