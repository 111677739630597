import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState } from 'react';
import { RaptorTheme, mainColors } from '../../../../../../styling/theme';
import client, { BASE_URL } from '../../../../../../utilities/requestClient';
import { FunctionalityHandlerProps } from '../../Functionality';
import FunctionalityButton from '../functionalityButton';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  calculateQuarter,
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../../../utilities/dateUtilities';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { clientConfigurationSelector } from '../../../../../../redux/pages/selectors';
import { CircularProgress, Typography } from '@mui/material';
import RaptorDatePicker from '../../../../../selects/RaptorDatePicker.component';
import { getAllRelevantDates } from '../../../../../../utilities/dateUtilities';

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  fdiContainer: {
    display: 'flex',
    position: 'relative',
    top: '1rem',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
  },
  header: {
    position: 'relative',
    // top: '0.5rem',
  },
  feedback: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 500,
  },
  loadingMessage: {
    color: mainColors.mainBlue,
  },
  errorMessage: {
    color: mainColors.Fail,
  },
  successMessage: {
    color: mainColors.Pass,
  },
}));

function RiskMetricsReport(props: FunctionalityHandlerProps): ReactElement {
  // Classes for fdiContainer
  const classes = useStyles();

  // End date for running the report (default to today)
  const [startDate, setStartDate] = useState<string>(getWeekdayXMonthsAgo(1));
  const [endDate, setEndDate] = useState<string>(getMostRecentWeekday());

  // Create a function for generating the url and calling the report when the button is pressed
  // const { showAsyncSnackbar } = useSnackbar();
  // async function handleRequest() {
  //   const url = `${BASE_URL}bespoke_reporting/risk_metrics_report/${startDate}/${endDate}`;
  //   const func = openInNewTab(url);
  //   await showAsyncSnackbar({
  //     startMessage: 'Generating Risk Metrics Report',
  //     successMessage: 'Report Sent',
  //     failureMessage: 'Failed to generate report',
  //     promiseToResolve: func(),
  //   });
  // }
  const clientConfig = useSelector(clientConfigurationSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageStatus, setMessageStatus] = useState<
    'success' | 'error' | 'loading'
  >('success');

  const handleRequest = () => {
    const url = `${BASE_URL}bespoke_reporting/risk_metrics_report/${startDate}/${endDate}`;
    const requestClient = client();
    setLoading(true);
    setMessageStatus('loading');
    setMessage('Generating Report...');
    requestClient
      .get(url, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const blob = response.data;

        saveAs(
          blob,
          `${
            clientConfig.configId.charAt(0).toUpperCase() +
            clientConfig.configId.slice(1)
          } ${calculateQuarter(endDate)} ${dayjs(
            endDate
          ).year()} Fund Statistics: ${startDate} to ${endDate}.xlsx`
        );

        setLoading(false);
        setMessageStatus('success');
        setMessage('Report Generated!');
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        setMessageStatus('error');
        setMessage('Failed to generate report.');
      })
      .finally(() => {
        setTimeout(() => {
          setMessageStatus('loading');
          setMessage('');
        }, 10000);
      });
  };

  return (
    <GridItem
      card
      xs={props.size}
      cardStyle={{ height: '100%', padding: '2rem' }}
    >
      <div className={classes.header}>
        <Typography
          variant="h2"
          style={{
            fontSize: '2.4rem',
            width: '100%',
            fontWeight: 500,
            textAlign: 'left',
          }}
          className={classes.header}
        >
          {props.cardTitle}
        </Typography>
      </div>
      <div className={classes.fdiContainer}>
        <RaptorDatePicker
          key={'start-date'}
          title={'Start Date: '}
          handler={(val) => {
            setStartDate(dayjs(val).format('YYYY-MM-DD'));
          }}
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(startDate)}
          tiedToActiveDate={false}
        />
        <RaptorDatePicker
          // key={picker.titleBarKey}
          title={'End Date: '}
          handler={(val) =>
            setEndDate(dayjs(val).format('YYYY-MM-DD'))
          }
          datesToInclude={getAllRelevantDates()}
          selectedDate={dayjs(endDate)}
          tiedToActiveDate={false}
        />
        <FunctionalityButton
          handler={handleRequest}
          title="Run Report"
          height="6rem"
        />
        <div className={classes.feedback}>
          <div
            className={
              messageStatus === 'success'
                ? classes.successMessage
                : messageStatus === 'error'
                ? classes.errorMessage
                : classes.loadingMessage
            }
          >
            {loading && (
              <CircularProgress size={20} style={{ marginRight: '1rem' }} />
            )}
            {message}
          </div>
        </div>
      </div>
    </GridItem>
  );
}

export default RiskMetricsReport;
