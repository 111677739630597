import { Switch, Tooltip, Typography } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import {
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { GeneralFunctionType } from '../types/functions.types';
import {
  UseFormSetValue,
  FormProvider,
  useForm,
  useFormContext,
  UseFormRegister,
} from 'react-hook-form';
import { RaptorTheme } from '../../../../styling/theme';
import {
  checkFormatValue,
  formatInitialValue,
  isCellEditable,
} from '../utils/ultratable.utils';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from '../../../toast/Toast';
import '../styles/ultratable.css';

export interface EditableFields {
  editable_fields: EditableFieldsProperties[];
  [key: string]: any;
}

export type EditableFieldsProperties = {
  from_selection: string[] | number[];
  displayed_type_name: string;
  raptor_type: string;
  key_name: string;
  raptor_tab_title: string;
  validation?: ValidationObject;
};

export type ValidationObject = {
  max: number;
  values: number[] | string[];
  min: number;
};

export type LocalEditType = {
  old: string | number;
  updated: string | number;
};

interface EditableCellProps {
  getValue: () => any;
  original: any;
  index: number;
  column: any;
  table: any;
  cell: any;
  setUpdateLog?: (log: any) => void;
  fn?: GeneralFunctionType;
}

// Define the styles
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: 50,
  },
  editableCell: {
    height: '100%',
    width: '100%',
    padding: '1rem',
    textAlign: 'center',
    border: 'none',
    background: 'var(--pastle-blue-lighter)',
    '&:hover': {
      outline: '2px solid blue',
      background: 'var(--pastle-blue-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '2px solid blue',
      background: 'var(--pastle-blue-light)',
      cursor: 'pointer',
    },
  },
  updated: {
    background: 'var(--pastle-green)',
    outline: '2px solid var(--pastle-green-darker)',
    '&:hover': {
      outline: '2px solid green',
      background: 'var(--pastle-green-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '2px solid green',
      background: 'var(--pastle-green-light)',
      cursor: 'pointer',
    },
  },
  error: {
    background: 'var(--pastle-red)',
    outline: '2px solid var(--pastle-red-darker)',
    '&:hover': {
      outline: '2px solid red',
      background: 'var(--pastle-red-light)',
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '2px solid red',
      background: 'var(--pastle-red-light)',
      cursor: 'pointer',
    },
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  actionBtnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    right: 0,
    top: 0,
  },

  actionBtn: {
    border: 'none',
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '.25rem',
    borderRadius: '5px',
    '& svg': {
      fontSize: '1.98rem',
      transition: 'filter 0.3s ease',
    },
  },

  confirmBtn: {
    '&:hover': {
      backgroundColor: '#d4edda', // Light green background on hover
      '& svg': {
        filter: 'brightness(1.2)',
      },
    },
  },

  cancelBtn: {
    '&:hover': {
      backgroundColor: '#f8d7da', // Light red background on hover
      '& svg': {
        filter: 'brightness(1.2)',
      },
    },
  },

  disabled: {
    cursor: 'not-allowed',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey', // Light green background on hover
    },
  },

  tooltip: {
    backgroundColor: 'var(--pastle-red-darker)',
  },
}));

export const EditableCellWrapper: React.FC<EditableCellProps> = (props) => {
  const methods = useForm({
    // defaultValues: {
    //   [props.column.id]: props.getValue(),
    // }, TODO: Figure out default values and which is correct key_name and prop
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <EditableCell {...props} />
    </FormProvider>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  getValue,
  original,
  index,
  column,
  table,
  setUpdateLog,
  fn,
}) => {
  const classes = useStyles();
  const [cellError, setCellError] = useState(false);
  const initialValue = getValue();
  const [value, setValue] = useState(formatInitialValue(initialValue));
  const [isUpdated, setIsUpdated] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { trigger } = useFormContext();

  const isEditable = isCellEditable(original, column);

  const onBlur = async (e: any) => {
    // This handles the initial cell click do not set the table state if ===
    const InitialValueIsCellValue =
      checkFormatValue(initialValue) == e.target.value;

    if (InitialValueIsCellValue) {
      table.options.meta?.updateData(index, column.id, value, true);
      return;
    }

    table.options.meta?.updateData(index, column.id, value);
    if (checkFormatValue(initialValue) !== value) {
      return setIsUpdated(true);
    } else {
      return setIsUpdated(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (checkFormatValue(initialValue) !== value) {
        if (setUpdateLog) {
          setUpdateLog((prevLog: any) => {
            const existingEntryIndex = prevLog.findIndex(
              (entry: any) =>
                entry.original === original && entry.columnId === column.id,
            );

            if (existingEntryIndex === -1) {
              return [...prevLog, { original, columnId: column.id, value }];
            } else {
              if (prevLog[existingEntryIndex].value !== value) {
                const updatedLog = [...prevLog];
                updatedLog[existingEntryIndex] = {
                  original,
                  columnId: column.id,
                  value,
                };
                return updatedLog;
              }
            }
            return prevLog;
          });
        }

        return setIsUpdated(true);
      } else {
        if (setUpdateLog) {
          setUpdateLog((prevLog: any) => {
            const existingEntryIndex = prevLog.findIndex(
              (entry: any) =>
                entry.original === original && entry.columnId === column.id,
            );

            if (existingEntryIndex !== -1) {
              const updatedLog = [...prevLog];
              updatedLog.splice(existingEntryIndex, 1);
              return updatedLog;
            }

            return prevLog;
          });
        }

        return setIsUpdated(false);
      }
    }, 100); // 100 milliseconds delay - This is due to the renderIssue with updated state UI and row filtering based on hidden edit cells. => This is a hacky solution => should be fixed but understanding how to be filter the editable cells out on presets

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [initialValue, setIsUpdated, value]);

  useEffect(() => {
    setValue(formatInitialValue(initialValue));
  }, [initialValue]);

  if (loading) {
    return <span className="general-loader"></span>;
  }

  const keyName = original.editable_fields.find(
    (field: any) => field.key_name === column.id,
  )!;

  async function handleSubmitEdits() {
    if (fn) {
      setLoading(true);
      try {
        const response = await fn(original, column.id, value);
        table.options.meta?.updateData(index, column.id, value, true);
        toast({
          title: 'Position Field Updated',
          message: `It will only affect calculations in the next run of the fund, unless you request a recalculation for this run.`,
          type: 'success',
          duration: 5000,
        });
        setIsUpdated(false);
      } catch (error) {
        toast({
          title: 'Error Updating Field',
          message: `${error}`,
          type: 'error',
        });
        setResponseError(true);
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleCancelEdits() {
    // NO idea why await works here to render the validation of trigger function to UI

    // Update the tanstack table state
    table.options.meta?.updateData(
      index,
      column.id,
      formatInitialValue(initialValue, true),
      true,
    );

    // Set the form value for the table cell - Await somehow works and delays the trigger
    await setValue(formatInitialValue(initialValue, true));

    // Trigger new validation for cell
    trigger(keyName?.key_name);
  }
  return isEditable ? (
    <div className={classes.container}>
      <EditableInput
        editOptions={
          original.editable_fields.find(
            (field: any) => field.key_name === column.id,
          )!
        }
        value={value}
        setValue={setValue}
        onBlur={onBlur}
        original={original}
        columnID={column.id}
        fn={fn}
        updated={isUpdated}
        responseError={responseError}
        setResponseError={setResponseError}
        setCellError={setCellError}
        handleSubmitEdits={handleSubmitEdits}
        handleCancelEdits={handleCancelEdits}
      />

      {isUpdated && (
        <div className={classes.actionBtnContainer}>
          <button
            className={clsx(classes.actionBtn, classes.confirmBtn, {
              [classes.disabled]: cellError,
            })}
            // disabled={cellError}
            onClick={handleSubmitEdits}
          >
            <CheckCircleOutlineIcon style={{ fill: 'green' }} />
          </button>

          <button
            className={clsx(classes.actionBtn, classes.cancelBtn)}
            onClick={handleCancelEdits}
          >
            <CancelIcon style={{ fill: 'red' }} />
          </button>
        </div>
      )}
    </div>
  ) : (
    <>{value as string}</>
  );
};

interface EditableInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: any;
  setValue: React.Dispatch<any>;
  editOptions: EditableFieldsProperties;
  original: any;
  columnID: string;
  fn?: GeneralFunctionType;
  updated: boolean;
  responseError?: boolean;
  setResponseError: React.Dispatch<React.SetStateAction<boolean>>;
  setCellError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitEdits: () => void;
  handleCancelEdits: () => void;
}

const EditableInput: FunctionComponent<EditableInputProps> = (props) => {
  const classes = useStyles();
  // const {
  //   register,
  //   formState: { errors },
  //   trigger,
  //   setValue: setFormValue, // Import setValue from useForm
  // } = useForm<any>({
  //   defaultValues: {
  //     [props.editOptions.key_name]: props.value,
  //   },
  //   mode: 'onChange',
  // });

  const {
    register,
    formState: { errors },
    trigger,
    setValue: setFormValue, // Import setValue from useFormContext
  } = useFormContext<any>();

  const { raptor_type: type, from_selection, validation } = props.editOptions;

  if (from_selection && from_selection.length == 2) {
    const truthy = props.original[props.columnID] === 'yes';

    return (
      <SwitchComponent
        {...props}
        classes={classes}
        columnID={props.columnID}
        checked={truthy}
        fn={props.fn}
      />
    );
  }

  const hasError =
    (errors && errors[props.editOptions.key_name]) || props.responseError;

  useEffect(() => {
    props.setCellError(!!hasError);
  }, [hasError]);

  const registerId = props.editOptions.key_name;

  useEffect(() => {
    if (typeof props.value === 'object') {
      console.log(props.value);
    }
    setFormValue(props.editOptions.key_name, props.value);
    // trigger(props.editOptions.key_name); // Trigger validation for the specific field // This is required for the scroll but will render all errors on screen immediately
  }, [props.value, props.editOptions.key_name, setFormValue, trigger]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {type === 'float' || type == 'integer' ? (
        <NumberEditInput
          onBlur={props.onBlur}
          validation={validation}
          registerId={registerId}
          props={props}
          errors={errors}
          register={register}
          setFormValue={setFormValue}
          setResponseError={props.setResponseError}
          responseError={props.responseError}
          handleCancelEdits={props.handleCancelEdits}
          handleSubmitEdits={props.handleSubmitEdits}
        />
      ) : (
        <input
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              props.handleSubmitEdits();
            }
            if (e.key === 'Escape') {
              props.handleCancelEdits();
            }
          }}
          onBlur={props.onBlur}
          value={props.value}
          className={clsx(classes.editableCell, {
            [classes.error]: props.responseError,
            [classes.updated]: !props.responseError && props.updated,
          })}
          onChange={(e) => {
            props.setResponseError(false);
            props.setValue(formatInitialValue(e.target.value));
          }}
        />
      )}
      {errors?.[registerId]?.message && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={errors?.[registerId]?.message?.toString() || ''}
          open={true}
        >
          {/* Hacky to have MUI tooltip */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'green',
              zIndex: -1,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

interface NumberEditInput extends React.InputHTMLAttributes<HTMLInputElement> {
  validation?: ValidationObject;
  registerId: string;
  props: {
    updated: boolean;
    editOptions: {
      key_name: string;
    };
    setValue: (value: number) => void;
    value: any;
  };
  errors: Record<string, any>;
  register: UseFormRegister<any>;
  responseError?: boolean;
  setResponseError: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValue: UseFormSetValue<any>;
  handleSubmitEdits: () => void;
  handleCancelEdits: () => void;
}

export const NumberEditInput: React.FC<NumberEditInput> = ({
  validation,
  setFormValue,
  props,
  errors,
  register,
  responseError,
  setResponseError,
  ...rest
}) => {
  const classes = useStyles();
  const { trigger } = useFormContext();
  // Determine the step value based on the minimum distance in the values array
  const stepValue = validation ? calculateMinStep(validation.values) : 0.01;

  const hasError =
    (errors && errors[props.editOptions.key_name]) || responseError;

  //UI validation errors as validation schema has outof bounds stored values
  return (
    <input
      className={clsx(classes.editableCell, {
        [classes.error]: hasError,
        [classes.updated]: !hasError && props.updated,
      })}
      // type="number"
      // inputMode="decimal"
      type="number"
      // pattern="[0-9]*"

      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          rest.handleSubmitEdits();
        }
        if (e.key === 'Escape') {
          rest.handleCancelEdits();
        }
      }}
      min={validation?.min}
      max={validation?.max}
      step={stepValue} // Dynamically set the step value
      {...register(props.editOptions.key_name, {
        required: 'This field is required',
        validate: (value) => {
          if (validation) {
            if (validation.values.length > 0) {
              return (
                validation.values.includes(+value as never) ||
                `Value must be one of ${validation.values.join(', ')}`
              );
            }
            return (
              (value >= validation.min && value <= validation.max) ||
              `Value must be between ${validation.min} and ${validation.max}`
            );
          }
          return true; // If validation object doesn't exist, consider the value valid
        },
        onChange: (e) => {
          trigger(props.editOptions.key_name);
          setResponseError(false);
          props.setValue(formatInitialValue(+e.target.value));
        },
        onBlur: (e) => {
          if (rest.onBlur) {
            rest.onBlur(e);
          }
        },
      })}
    />
  );
};

interface SwitchComponentProps extends EditableInputProps {
  classes: ClassNameMap;
  fn?: GeneralFunctionType;
  columnID: string;
}

// Passing the fn fucntion here is messy, come up with more ideal

const SwitchComponent: FunctionComponent<SwitchComponentProps> = (props) => {
  const { classes } = props;
  const [isChecked, setIsChecked] = useState(props.checked); // Not ideally linked to the database

  const etcStatus = isChecked ? 'no' : 'yes';

  return (
    <div className={classes.switch}>
      <Typography>No</Typography>
      <Switch
        checked={isChecked}
        onChange={() => {
          setIsChecked((prev) => !prev);
          props.setValue(etcStatus);
        }}
      />
      <Typography>Yes</Typography>
    </div>
  );
};

const calculateMinStep = (values: number[] | string[]): number => {
  if (values.length < 2) return 0.01; // Default step if not enough values to compare
  let minStep = Number.MAX_VALUE;
  for (let i = 1; i < values.length; i++) {
    const step = +values[i] - +values[i - 1];
    if (step < minStep) {
      minStep = step;
    }
  }
  return minStep;
};
