import React, { FC, useEffect, useState } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { useDispatch } from 'react-redux';
import { updateSection } from '../../../../../redux/pages/actions';
import client from '../../../../../utilities/requestClient';
import makeStyles from '@mui/styles/makeStyles';
import { RaptorTheme, greys, mainColors } from '../../../../../styling/theme';
import UcitsKiidsOverviewSecondLevelTableWrapper from './UcitsKiidsOverviewSecondLevelTableWrapper.component';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import DownloadFileButton from '../buttons/DownloadFilesButton.component';
import { Tooltip } from '@mui/material';

// Define some styles for th page
const useStyles = makeStyles<RaptorTheme>((theme) => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  generalSelectContainer: {
    width: '20%',
    marginLeft: '2rem',
    marginTop: '2rem',
  },
  downloadButtonContainer: {
    display: 'flex',
    // width: '20%',
    height: '3rem',
    marginTop: '2rem',
    marginRight: '2rem',
    cursor: 'pointer',
  },
}));
export interface UcitsKiidsOverviewTableData {
  fundId: string;
  fundUUID: string;
  fundName: string;
  numShareClasses: number;
  unresolvedComments: number;
  secondLevelData: UcitsKiidsOverviewSecondLevelTableData[];
}
export interface UcitsKiidsOverviewSecondLevelTableData {
  fundId: string;
  fundName: string;
  isin: string;
  publicationDate: string;
  lasteEditedDate: string;
  lastEditedBy: string;
  version: string;
  fund_uuid: string;
  kiid_uuid: string;
  unresolvedComments: number;
  versionNumbers: string[];
  index: number;
  documentLanguage: string;
  shareClassName: string;
  versionStatus: string;
}

// Function to display a message based on the version number of the document
function getVersionStatus(version: string): string {
  // Split the version number into major, minor and patch
  const versionNumbers = version.split('.');
  const major = parseInt(versionNumbers[0]);
  const minor = parseInt(versionNumbers[1]);
  const patch = parseInt(versionNumbers[2]);
  // If the patch is greate than 0 then edits have been made.
  if (patch > 0) return 'Edits made to document, review is available.';
  // If the minor is greater than 0 and the path is 0 then a review is available
  if (minor > 0) return 'Review completed, document is availbe for publishing.';
  // Otherwise the document has been published (minor and patch are 0)
  return 'Latest Published version, no changes have been made.';
}

function buildKiidsEditorOverviewTableData(
  data: any[],
  selectedLanguage: string,
): UcitsKiidsOverviewTableData[] {
  // Function for creating and flitering the data for the overview table
  if (!data) return [];
  if (!data.length) return [];
  if (!selectedLanguage) return [];
  if (selectedLanguage === '') return [];
  const kiidsData = data;
  const tableData: UcitsKiidsOverviewTableData[] = [];
  // Create an object for storing the data.
  type DataStore = {
    [key: string]: UcitsKiidsOverviewTableData;
  };
  const dataStore: DataStore = {};
  // Loop through the data and add to the data store.
  kiidsData
    .filter((value: any) => value.document_language === selectedLanguage)
    .forEach((value: any, index: number) => {
      // Build the second level data first
      const secondLevelData: UcitsKiidsOverviewSecondLevelTableData = {
        fundId: value.fund_id_string,
        fundName: value.fund_name,
        isin: value.share_class,
        publicationDate: value.publication_timestamp,
        lasteEditedDate: value.last_edit_timestamp,
        lastEditedBy: value.last_edited_by_name,
        version: value.version,
        fund_uuid: value.fund_id,
        kiid_uuid: value.kiid_id,
        unresolvedComments: value.num_unresolved_comments,
        versionNumbers: value.version_numbers,
        index: index,
        documentLanguage: value.document_language,
        shareClassName: value.share_class_name,
        versionStatus: getVersionStatus(value.version),
      };
      // Check if this fund is NOT already in the data store
      if (!(value.fund_id_string in dataStore)) {
        secondLevelData.index = 0;
        // Create a new entry in the datastore
        dataStore[value.fund_id_string] = {
          fundId: value.fund_id_string,
          fundUUID: value.fund_id,
          fundName: value.fund_name,
          numShareClasses: 1,
          unresolvedComments: value.num_unresolved_comments,
          secondLevelData: [secondLevelData],
        };
      } else {
        // Otherwise just increment number of share classes and append to second level data.
        dataStore[value.fund_id_string].numShareClasses += 1;
        dataStore[value.fund_id_string].unresolvedComments +=
          value.num_unresolved_comments;
        secondLevelData.index =
          dataStore[value.fund_id_string].secondLevelData.length;
        dataStore[value.fund_id_string].secondLevelData.push(secondLevelData);
      }
    });
  // Get just the values form the datastore and return this.
  const dataStoreValues = Object.values(dataStore);

  return dataStoreValues;
}

// Function for building the columns for the table based on the download handler provided
function buildTableColumns(
  mancoId: string,
  selectedLanguage: string,
): CustomColumn<UcitsKiidsOverviewTableData>[] {
  const detailColumns: CustomColumn<UcitsKiidsOverviewTableData>[] = [
    {
      title: 'Fund ID',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      defaultSort: 'asc',
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: '#Share Classes',
      field: 'numShareClasses',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Download',
      field: '',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: UcitsKiidsOverviewTableData) => {
        return (
          <DownloadFileButton
            mancoId={mancoId}
            fundId={rowData.fundId}
            fundName={rowData.fundName}
            tooltipMessage="Download documents for all share classes in this fund. This may take a few moments to complete."
            documentLanguage={selectedLanguage}
          />
        );
      },
    },
  ];
  return detailColumns;
}

// Define a function for finding the primary language in the data
function findPrimaryLanguage(data: any[]): string {
  if (!data) return '';
  if (!data.length) return '';
  let primaryLanguage = '';
  data.forEach((value: any) => {
    // NB - HACK FOR DEMO MAKE SURE TO REMOVE!!!!!!!
    if (value.document_language === 'English') {
      primaryLanguage = value.document_language;
      // break out of the loop
      return;
    }
    if (value.is_primary_language) {
      primaryLanguage = value.document_language;
      // break out of the loop
      return;
    }
  });
  return primaryLanguage;
}

const UcitsKiidsOverview: FC = (props) => {
  // Need to add this here for the moment until the routing is improved
  const dispatch = useDispatch();
  dispatch(updateSection('raidr'));
  const requestClient = client();

  // Create an object for storing the overview data
  const [priipsOverviewData, setPriipsOverviewData] = useState<any[] | null>(
    null,
  );
  // Create objects for storing the selected and available languages in the data
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');
  const [languageOptions, setLanguageOptions] = React.useState<string[]>([]);
  const [primaryLanguage, setPrimaryLanguage] = React.useState<string>('');
  // Create an object to store the table data
  const [tableData, setTableData] = React.useState<
    UcitsKiidsOverviewTableData[]
  >([]);

  // Function for pulling and populating the overview data
  const getPriipsOverviewData = async () => {
    requestClient.get('kiids_generator_get_overview_data').then((response) => {
      setPriipsOverviewData(response.data);
    });
  };
  // Run the function once on render
  useEffect(() => {
    getPriipsOverviewData();
  }, []);

  // Once the overview data is populated, pull the unique language oprions from the data
  useEffect(() => {
    if (priipsOverviewData && priipsOverviewData.length) {
      const languages = priipsOverviewData.map(
        (value: any) => value.document_language,
      );
      const uniqueLanguages = [...new Set(languages)];
      setLanguageOptions(uniqueLanguages);
      const primary = findPrimaryLanguage(priipsOverviewData);
      setSelectedLanguage(primary);
      setPrimaryLanguage(primary);
    }
  }, [priipsOverviewData]);

  // Once the language is set (or changed) fileter the overview data to only show the selected language
  useEffect(() => {
    if (priipsOverviewData && priipsOverviewData.length) {
      const filteredTableData = buildKiidsEditorOverviewTableData(
        priipsOverviewData,
        selectedLanguage,
      );
      setTableData(filteredTableData);
    }
  }, [selectedLanguage]);

  const columns = buildTableColumns(
    priipsOverviewData && priipsOverviewData.length
      ? priipsOverviewData[0].manco_id
      : '',
    selectedLanguage,
  );

  const classes = useStyles();

  // get the current page (decides what title to add to the table)
  // const currentPage = useSelector(activePageSelector);
  const currentUrl = window.location.href;
  const currentPage = currentUrl.split('/')[currentUrl.split('/').length - 1];
  return (
    <GridItem xs={12} card>
      <div className={classes.headerContainer}>
        <div className={classes.generalSelectContainer}>
          {selectedLanguage !== '' && (
            <Tooltip
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title="Switch between the different languages available for the documents."
              placement={'top'}
              arrow
              enterNextDelay={2000}
              enterDelay={2000}
            >
              <RaptorSelect
                options={languageOptions}
                handleChoice={setSelectedLanguage}
                defaultOption={selectedLanguage}
                textColor="white"
                backgroundColor={mainColors.mainBlue}
                hoverColor={mainColors.mainBlue_lighter}
              />
            </Tooltip>
          )}
        </div>
        <div className={classes.downloadButtonContainer}>
          <DownloadFileButton
            mancoId={
              priipsOverviewData && priipsOverviewData.length
                ? priipsOverviewData[0].manco_id
                : ''
            }
            tooltipMessage="Download all available documents. This may take a few moments to complete."
            documentLanguage={selectedLanguage}
          />
        </div>
      </div>
      <CustomTable<UcitsKiidsOverviewTableData>
        title={'UCITS KIID Editor'}
        showToolbar={true}
        id={`kiids_editor_overview_table`}
        loading={priipsOverviewData && priipsOverviewData.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        detailPanel={UcitsKiidsOverviewSecondLevelTableWrapper(
          currentPage?.endsWith('ucits-kiids-view')
            ? 'viewOnly'
            : 'allFunctionality',
        )}
        columns={columns}
      />
    </GridItem>
  );
};

export default UcitsKiidsOverview;
