import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import useFetchData from '../../../../hooks/useFetchData';
import { createSpecificTitleBarDatePickerSelector } from '../../../../redux/pages/selectors';
import { DatePicker } from '../../../../types/redux/pages/PagesStore';
import {
  formatDateForCheckingState,
  todayFormatted,
} from '../../../../utilities/dateFormatters';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralFundInfoPageProps } from '../../../general/GeneralFundInfoPage';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import dayjs from 'dayjs';
import { HistoricalFailsTables } from './HistoricalFailsTable.component';
import { Typography } from '@mui/material';

/**
 * Hook to make use of a start date and end date in the titlebar
 * @returns either undefined for both dates or the value of both
 */
function useDatePickers() {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };

  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };

  useAdditionalTitleBarDatePicker(startDatePicker);
  useAdditionalTitleBarDatePicker(endDatePicker);

  const startDate = useSelector(
    createSpecificTitleBarDatePickerSelector('startDate')
  );
  const endDate = useSelector(
    createSpecificTitleBarDatePickerSelector('endDate')
  );
  return {
    startDate: dayjs(startDate[0]?.currentValue).toDate(),
    endDate: dayjs(endDate[0]?.currentValue).toDate(),
  };
}

function useFailsDataFetch(
  startDate: Date | undefined,
  endDate: Date | undefined,
  fundId?: string
) {
  const startDateForFetch = startDate
    ? formatDateForCheckingState(startDate)
    : todayFormatted();
  const endDateForFetch = endDate
    ? formatDateForCheckingState(endDate)
    : todayFormatted();

  const dataFetch = useFetchData({
    keyName: `historical_fails_${startDateForFetch}_${endDateForFetch}_${
      fundId ? `_${fundId}` : ''
    }`,
    url: `/historical_fails/${fundId}/${startDateForFetch}/${endDateForFetch}`,
    makeFetch:
      startDate !== undefined && endDate !== undefined && fundId !== undefined,
  });
  return dataFetch;
}

export function FailsPageWithoutFund() {
  return (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h2">Please Select a Fund</Typography>
    </DisplayAreaCenteredWrapper>
  );
}

// this is the fails page if we have a fund Id
function HistoricalFailsPage({
  fundId,
}: GeneralFundInfoPageProps): ReactElement {
  const { startDate, endDate } = useDatePickers();
  const startDateForFetch = startDate
    ? formatDateForCheckingState(startDate)
    : todayFormatted();
  const endDateForFetch = endDate
    ? formatDateForCheckingState(endDate)
    : todayFormatted();

  const fundFails = useFetchData({
    keyName: `historical_fails_${startDateForFetch}_${endDateForFetch}_${
      fundId ? `_${fundId}` : ''
    }`,
    url: `/historical_fails/${fundId}/${startDateForFetch}/${endDateForFetch}`,
    makeFetch:
      startDate !== undefined && endDate !== undefined && fundId !== undefined,
  });

  return (
    <GeneralComponentErrorShield dataObjects={[fundFails]}>
      <HistoricalFailsTables data={fundFails ? fundFails.data[0] : {}} />
    </GeneralComponentErrorShield>
  );
}

export default HistoricalFailsPage;
