import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoggedInRoute from './LoggedInRoute';
import { updateSection } from '../redux/pages/actions';
import PriipsKidsOverviewAllFunctionality from '../components/pages/raidr/priips/overview/PriipsKidsOverviewAllFunctionality.component';
import UcitsKiidsOverview from '../components/pages/raidr/ucitsKiids/overview/UcitsKiidsOverview.component';
import DelegatedActsOverview from '../components/pages/raidr/delegatedActs/overview/DelegatedActsOverview.component';
import FactSheetsOverview from '../components/pages/raidr/factSheets/overview/FactSheetsOverview.component';
import DelegatedActsEditor from '../components/pages/raidr/delegatedActs/editor/DelegatedActsEditor.component';
import FactSheetsEditor from '../components/pages/raidr/factSheets/editor/FactSheetsEditor.component';
import PriipsKidsEditor from '../components/pages/raidr/priips/editor/PriipsKidsEditor.component';
import UcitsKiidsEditor from '../components/pages/raidr/ucitsKiids/editor/UcitsKiidsEditor.component';
import PriipsKidsReview from '../components/pages/raidr/priips/review/PriipsKidsReview.component';
import UcitsKiidsReview from '../components/pages/raidr/ucitsKiids/review/UcitsKiidsReview.component';
import PriipsKidsCompareVersions from '../components/pages/raidr/priips/compareVersions/PriipsCompareVersions.component';
import UcitsKiidsCompareVersions from '../components/pages/raidr/ucitsKiids/compareVersions/UcitsKiidsCompareVersions.component';
import PriipsKidsView from '../components/pages/raidr/priips/view/PriipsKidsView.component';
import UcitsKiidsView from '../components/pages/raidr/ucitsKiids/view/UcitsKiidsView.component';
import KidsFigures from '../components/pages/raidr/kidsFigures/KidsFigures.component';
import RaidrKidsFigures from '../components/pages/raidr/kidsFigures/RaidrKidsFigures.component';

const RaidrRouter: FC = (props) => {
  // Udpate the active section to raidr
  const dispatch = useDispatch();
  dispatch(updateSection('raidr'));

  return (
    // Here we route to different sections of the delegated acts functionality
    <Switch>
      <LoggedInRoute
        exact
        path="/raidr/priips-kids-overview"
        Component={PriipsKidsOverviewAllFunctionality} // Routes updated
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-overview-edit" // Routes updated
        Component={UcitsKiidsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-overview-view" // Routes updated
        Component={UcitsKiidsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/delegated-acts-overview" // Routes updated
        Component={DelegatedActsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/fact-sheets-overview" // Routes updated
        Component={FactSheetsOverview}
      />
      <LoggedInRoute
        exact
        path="/raidr/delegated-acts-editor" // Routes updated
        Component={DelegatedActsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/fact-sheets-editor" // Routes updated
        Component={FactSheetsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids-editor" // Routes updated
        Component={PriipsKidsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-editor" // Routes updated
        Component={UcitsKiidsEditor}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids-review" // Routes updated
        Component={PriipsKidsReview}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-review" // Routes updated
        Component={UcitsKiidsReview}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids-compare-versions" // Routes updated
        Component={PriipsKidsCompareVersions}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-compare-versions" // Routes updated
        Component={UcitsKiidsCompareVersions}
      />
      <LoggedInRoute
        exact
        path="/raidr/priips-kids-view" // Routes updated
        Component={PriipsKidsView}
      />
      <LoggedInRoute
        exact
        path="/raidr/ucits-kiids-view" // Routes updated
        Component={UcitsKiidsView}
      />
      <LoggedInRoute
        exact
        path="/raidr/kids-figures" // Routes updated
        Component={RaidrKidsFigures}
      />

      <Redirect
        exact
        from="/raidr/delegated-acts"
        to="/raidr/delegated-acts-overview"
      />
      <Redirect
        exact
        from="/raidr/fact-sheets"
        to="/raidr/fact-sheets-overview"
      />
      <Redirect
        exact
        from="/raidr/priips-kids"
        to="/raidr/priips-kids-overview"
      />
      <Redirect
        exact
        from="/raidr/ucits-kiids"
        to="/raidr/ucits-kiids-overview-view"
      />
    </Switch>
  );
};

export default RaidrRouter;
