import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../styling/theme';
import { FC, useEffect, useState } from 'react';
import client from '../../../../../utilities/requestClient';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import BackToOverviewButton from '../buttons/BackToOverviewButton.component';
import PriipsKidsEditorGridItem from '../layout/PriipsKidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { userIdSelector } from '../../../../../redux/auth/selectors';

const useStyles = makeStyles(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  button: {
    height: '3rem',
    // marginTop: '0.2rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '85vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  fundNameContainer: {
    width: '300%',
    overflow: 'hidden',
    maxHeight: '3rem',
    marginRight: '1rem',
    marginLeft: '1rem',
  },
}));

const UcitsKiidsCompareVersions: FC = () => {
  const classes = useStyles();
  const requestClient = client();
  const history = useHistory();
  const dispatch = useDispatch();

  const [reviewContentData, setReviewContentData] = useState<any[]>([]);
  const [pdfDocLoading, setPdfDocLoading] = useState(false);

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const targetVersion = useRouteParams('targetVersion')['targetVersion'];
  const comparisonVersion =
    useRouteParams('comparisonVersion')['comparisonVersion'];

  const getReviewData = async () => {
    requestClient
      .get(
        `kiid_generator_get_review_changes/${fundId}/${isin}/${targetVersion}/${comparisonVersion}/${documentLanguage}`,
      )
      .then((response) => {
        setReviewContentData(response.data.data);
      });
  };

  useEffect(() => {
    if (
      fundId &&
      isin &&
      documentLanguage &&
      targetVersion &&
      comparisonVersion
    ) {
      getReviewData();
    }
  }, [fundId, isin, documentLanguage, targetVersion, comparisonVersion]);

  const getPDF = async () => {
    // Check if the required fields have been filled in.
    if (!reviewContentData || !reviewContentData.length) {
      return;
    }

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const priipsKidsJson = JSON.stringify(reviewContentData);
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('params', JSON.stringify(['contents']));

    // Display the loading symbol
    setPdfDocLoading(true);

    requestClient
      .post('risksystem_pdf_generator/generate_new_review_kiid', formData, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      })
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = 'kiid.pdf';
        }
        setPdfDocLoading(false);
      });
  };

  useEffect(() => {
    if (reviewContentData.length) {
      getPDF();
    }
  }, [reviewContentData]);

  // [Tom Walsh, 2024-10-08] - In order to fix long running RAIDR bugs, temporary hack here to route depending on user_name
  // The reason is that coutts_funds has the page ucits-kiids-overview-view but coutts_product_team has the page ucits-kiids-overview-edit
  const userName = useSelector(userIdSelector);
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    if (userName === 'coutts_funds') {
      history.push('ucits-kiids-overview-view');
    } else {
      history.push('ucits-kiids-overview-edit');
    };
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (reviewContentData.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewButton
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <div className={classes.fundNameContainer}>
            <Typography variant="h6">
              {`${reviewContentData[0].comparison[0].fund_name} - ${isin}`}
            </Typography>
          </div>,
        ]),
      );
      dispatch(toggleControlBarShowing(true));
    }
  }, [reviewContentData]);

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.viewerContainer}>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={pdfDocLoading ? '0%' : '100%'}
            height="100%"
            title={'kid.pdf'}
          ></iframe>
          {pdfDocLoading ? (
            <Raptor2Loading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default UcitsKiidsCompareVersions;
