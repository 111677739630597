import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSection } from '../../../../../redux/pages/actions';
import client from '../../../../../utilities/requestClient';
import { useHistory } from 'react-router-dom';
import useRouteParams from '../../../../../hooks/useRouteParams';
import PriipsKidsEditorColumn from './editorTiles/PriipsKidsEditorColumn.component';
import makeStyles from '@mui/styles/makeStyles';
import { mainColors } from '../../../../../styling/theme';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import ControlBarOptions from '../layout/ControlBarOptions.component';
import BackToOverviewButton from '../buttons/BackToOverviewButton.component';
import { v4 as uuidv4 } from 'uuid';
import EditorPdfViewer from './viewer/EditorPdfViewer.component';
import { isEqual } from 'lodash';
import {
  setAllowUserToNavigateAway,
  setDontAllowUserToNavigateAway,
} from '../../../../../redux/kids/actions';
import { userIdSelector } from '../../../../../redux/auth/selectors';

export interface PriipsKidsData {
  formatOptions: string;
  mancoId: string;
  tag: string;
  content: string;
  version: string;
  editedByName: string;
  editTimestamp: string;
  editedBy: string;
  fundName: string;
  commentId: string | null;
  fieldId: string;
  publishedBy: string;
  kiidId: string;
  kiidIndex: number;
  shareClassName: string;
  shareClass: string;
  fundIdString: string;
  hasUnresolvedComment: boolean;
  documentLanguage: string;
  publishedByName: string;
  fundId: string;
  isPublished: boolean;
  templateId?: string;
  templateType?: string;
}

export function formatPriipsKidsData(data: any[]): PriipsKidsData[] {
  if (!data) return [];
  if (!data.length) return [];
  const kidsData: PriipsKidsData[] = [];
  data.forEach((element: any) => {
    let content = element.content;
    let templateId = undefined;
    let templateType = undefined;
    // Check if the element is a template field
    if (element.content.startsWith('{{') && element.content.endsWith('}}')) {
      const templateData = JSON.parse(
        element.content.replace('{{', '{').replace('}}', '}'),
      );
      content = templateData.text;
      templateId = templateData.id;
      templateType = templateData.type;
    }
    kidsData.push({
      formatOptions: element.format_options,
      mancoId: element.manco_id,
      tag: element.tag,
      content: content,
      version: element.version,
      editedByName: element.edited_by_name,
      editTimestamp: element.edit_timestamp,
      editedBy: element.edited_by,
      fundName: element.fund_name,
      commentId: element.comment_id,
      fieldId: element.field_id,
      publishedBy: element.published_by,
      kiidId: element.kiid_id,
      kiidIndex: element.kiid_index,
      shareClassName: element.share_class_name,
      shareClass: element.share_class,
      fundIdString: element.fund_id_string,
      hasUnresolvedComment: element.has_unresolved_comment,
      documentLanguage: element.document_language,
      publishedByName: element.published_by_name,
      fundId: element.fund_id,
      isPublished: element.is_published,
      templateId: templateId,
      templateType: templateType,
    });
  });
  // Order the kids data by the kiid index
  kidsData.sort((a, b) => (a.kiidIndex > b.kiidIndex ? 1 : -1));
  return kidsData;
}

const useStyles = makeStyles(() => ({
  editorPageParent: {
    // width: '100vw',
    display: 'flex',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    height: '100%',
  },
  editorContainer: {
    maxWidth: '80rem',
    width: '100%',
    padding: '0 1rem',
    overflowY: 'auto',
    // overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
  },
  viewerContainer: {
    // position: 'sticky',
    // top: 0
    // overflow: 'hidden',
    width: '100%',
  },
}));
const UcitsKiidsEditor: FC = () => {
  const [editorColumnKey, setEditorColumnKey] = useState('');
  const [dataLength, setDataLength] = useState(0);
  // Need to add this here for the moment until the routing is improved
  const dispatch = useDispatch();
  dispatch(updateSection('raidr'));

  const requestClient = client();
  // Create an object for storing the kids content data
  const [priipsKidsContent, setPriipsKidsContent] = useState<PriipsKidsData[]>(
    [],
  );
  // Create an object for storing the kids Original content data
  const [originalPriipsKidsContent, setOriginalPriipsKidsContent] = useState<
    PriipsKidsData[]
  >([]);
  // Create an object for indicating if the columnn should be rerendered
  const [forceRerender, setForceRerender] = useState(false);
  // Create an object for indictaing if the editor tile should be filtered.
  const [templateFilter, setTemplateFilter] = useState('No Filter');
  // Create an object for indicating if changes have been made and can be saved
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  // when the user saves an update we want to update the "original" data to take the current version of the data
  // thiswill ensure things like the save button being disabled immediately after saving the data happens correctly
  function resetContentAfterSave() {
    setIsSaveEnabled(false);
    setOriginalPriipsKidsContent([]);
    setPriipsKidsContent([]);
    requestKiidContentData();
    // Make sure the user can navigate away from the page
    // window.onbeforeunload = () => undefined;
  }

  // This function will be called when an update is made to a template field.
  const getTemplateChangesMade = () => {
    if (
      priipsKidsContent.length === 0 ||
      originalPriipsKidsContent.length === 0
    ) {
      return [];
    }
    // Create a list for storing the fieldIds of the template fields that have been changed
    const changedTemplateFieldIds: string[] = [];
    priipsKidsContent.forEach((element: PriipsKidsData) => {
      // Check if the element is a template field
      if (element.templateId && element.templateType) {
        // Try to find the matching element in the original data using the field id
        const originalElement = originalPriipsKidsContent.find(
          (originalElement: PriipsKidsData) =>
            originalElement.fieldId === element.fieldId,
        );
        // Check if the element was found
        if (originalElement) {
          // Check if the content has changed
          if (element.content !== originalElement.content) {
            // If the content has changed then set the wereTemplateChangesMade to true
            changedTemplateFieldIds.push(element.fieldId);
          }
        } else {
          // If the element was not found then the template has been added
          changedTemplateFieldIds.push(element.fieldId);
        }
      }
    });
    return changedTemplateFieldIds;
  };

  // This function will be called when the user clicks the save button
  // It will check the data to ensure that it is valid before allowing the user to save to the database.
  const getCanUserSave = () => {
    // Create variables that will be used to indicate various problems that would prevent the user from saving
    // There must be exactly one SRI chart contained in the document.
    let sriChartCount = 0;
    // There must bve exactly one performance scenario table contained in the document.
    let performanceScenarioTableCount = 0;
    // There must be exactly one costs over time table contained in the document.
    let costsOverTimeTableCount = 0;
    // There must be exactly one composition of costs table contained in the document.
    let compositionOfCostsTableCount = 0;
    // There can be at most 2 page breaks in the document (max allowed page count is 3)
    let pageBreakCount = 0;
    // Loop over each element in the priips kids content
    priipsKidsContent.forEach((element: PriipsKidsData) => {
      // Check if the template type is an SRI chart
      if (element.tag === 'srri_chart') {
        // Increment the sri chart count
        sriChartCount++;
      }
      // Check if the template type is a performance scenario table
      if (element.tag === 'table_style4') {
        // Increment the performance scenario table count
        performanceScenarioTableCount++;
      }
      // Check if the template type is a costs over time table
      if (element.tag === 'table_style5') {
        // Increment the costs over time table count
        costsOverTimeTableCount++;
      }
      // Check if the template type is a composition of costs table
      if (element.tag === 'table_style6') {
        // Increment the composition of costs table count
        compositionOfCostsTableCount++;
      }
      // Check if the template type is a page break
      if (element.tag === 'page_break') {
        // Increment the page break count
        pageBreakCount++;
      }
    });
    // Now add any error messages that are required
    const errorMessages: string[] = [];
    // Check if there are any SRI charts
    if (sriChartCount !== 1) {
      errorMessages.push(
        'There must be exactly one SRI chart in the document.',
      );
    }
    // Check if there are any performance scenario tables
    if (performanceScenarioTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Performance Scenario table in the document.',
      );
    }
    // Check if there are any costs over time tables
    if (costsOverTimeTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Costs Over Time table in the document.',
      );
    }
    // Check if there are any composition of costs tables
    if (compositionOfCostsTableCount !== 1) {
      errorMessages.push(
        'There must be exactly one Composition of Costs table in the document.',
      );
    }
    // Check if there are any page breaks
    if (pageBreakCount > 2) {
      errorMessages.push(
        'Too many page breaks added. Document can be at most 3 pages long.',
      );
    }
    // Update the error messages
    return errorMessages;
  };
  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];

  // Create a function for pulling the kids content data
  const requestKiidContentData = async () => {
    requestClient
      .get(`kiid_generator_get_kiid_data/${fundId}/${isin}/${documentLanguage}`)
      .then((response) => {
        setPriipsKidsContent(formatPriipsKidsData(response.data));
        setOriginalPriipsKidsContent(formatPriipsKidsData(response.data));
        setDataLength(response.data.length);
      });
  };

  // Call the function for pulling the kids content data when all params are available
  useEffect(() => {
    if (fundId && isin && documentLanguage) {
      requestKiidContentData();
    }
  }, [fundId, isin, documentLanguage]);

  // Create a function for handling the back to overview button
  const history = useHistory();
  
  // [Tom Walsh, 2024-10-08] - In order to fix long running RAIDR bugs, temporary hack here to route depending on user_name
  // The reason is that coutts_funds has the page ucits-kiids-overview-view but coutts_product_team has the page ucits-kiids-overview-edit
  const userName = useSelector(userIdSelector);
  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    dispatch(setAllowUserToNavigateAway());
    if (userName === 'coutts_funds') {
      history.push('ucits-kiids-overview-view');
    } else {
      history.push('ucits-kiids-overview-edit');
    };
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (priipsKidsContent.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewButton
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <ControlBarOptions
            fundName={`${priipsKidsContent[0].fundName} - ${isin}`}
            setPriipsKidsContent={setPriipsKidsContent}
            fundId={priipsKidsContent[0].fundId}
            shareClass={priipsKidsContent[0].shareClass}
            setForceRerender={setForceRerender}
            setTemplateFilter={setTemplateFilter}
          />,
        ]),
      );
      dispatch(toggleControlBarShowing(true));
    }
  }, [priipsKidsContent]);

  const classes = useStyles();
  // This useEffect us used to force the editor column to rerender whenever the priips kids
  useEffect(() => {
    // If the number of elements in the priips kids data has changed then we need to force the editor column to rerender
    if (priipsKidsContent.length !== dataLength || forceRerender) {
      // Create a new key for the column
      const newColumnKey = uuidv4();
      setEditorColumnKey(newColumnKey);
      setDataLength(priipsKidsContent.length);
      setForceRerender(false);
    }
    // If the priips kids content has changed then we need to enable the save button
    if (originalPriipsKidsContent.length === 0) {
      setIsSaveEnabled(false);
      // Make sure the user can navigate away from the page
      window.onbeforeunload = () => undefined;
      dispatch(setAllowUserToNavigateAway());
    } else if (!isEqual(priipsKidsContent, originalPriipsKidsContent)) {
      setIsSaveEnabled(true);
      // IF saving is enabled then the data has been updated in some way.
      // in this case we want to prevent the user from navigating away from the page without saving
      window.onbeforeunload = () => true;
      dispatch(setDontAllowUserToNavigateAway());
    } else {
      setIsSaveEnabled(false);
      // Make sure the user can navigate away from the page
      window.onbeforeunload = () => undefined;
      dispatch(setAllowUserToNavigateAway());
    }
  }, [priipsKidsContent]);

  return (
    <div className={classes.editorPageParent}>
      <div className={classes.editorContainer}>
        <PriipsKidsEditorColumn
          key={editorColumnKey}
          priipsKidsData={priipsKidsContent}
          setPriipsKidsContent={setPriipsKidsContent}
          filter={templateFilter}
        />
      </div>
      <div className={classes.viewerContainer}>
        {priipsKidsContent.length > 0 && (
          <EditorPdfViewer
            priipsKidsData={priipsKidsContent}
            isSaveEnabled={isSaveEnabled}
            getTemplateChangesMade={getTemplateChangesMade}
            getCanUserSave={getCanUserSave}
            resetContentAfterSave={resetContentAfterSave}
          />
        )}
      </div>
    </div>
  );
};

export default UcitsKiidsEditor;
