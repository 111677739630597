import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import { greys, mainColors } from '../../../../../styling/theme';
import client from '../../../../../utilities/requestClient';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';

interface ReviewDocumentsButtonsProps {
  fundId: string;
  isin: string;
  documentLanguage: string;
  documentOptions: string[];
  filteredOverviewData: any[];
  defaultSelection: string;
}

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '1rem',
    gap: '2rem',
  },
  selectContainer: {
    width: '100%',
    minWidth: '60rem',
    // marginLeft: '10rem',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  arrowSelect: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltipSelect: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const ReviewDocumentsButtons: FC<ReviewDocumentsButtonsProps> = ({
  fundId,
  isin,
  documentLanguage,
  documentOptions,
  filteredOverviewData,
  defaultSelection,
}) => {

  const history = useHistory();
  const classes = useStyles();

  // This function will switch the document being reviewed when the user selects a different option from the dropdown
  function onSeletionChange(choice: string) {
    // Find the index of the selection in the options array
    const index = documentOptions.indexOf(choice);
    // Use the index to find the corresponding item in the overview data array
    const rowData = filteredOverviewData[index];
    history.push(
      `review?fundId=${rowData.fund_id_string}&isin=${
        rowData.share_class
      }&documentLanguage=${rowData.document_language}&currentVersion=${
        rowData.version
      }&publishedVersion=${rowData.version.split('.')[0] + '.0.0'}`,
    );
  }

  return (
    <div className={classes.buttonsContainer}>
      <Tooltip
        classes={{ arrow: classes.arrowSelect, tooltip: classes.tooltipSelect }}
        title="Select Document for Review"
        placement={'top'}
        arrow
        enterNextDelay={2000}
        enterDelay={2000}
      >
        <div className={classes.selectContainer}>
          {defaultSelection !== '' && (
            <RaptorSelect
              options={documentOptions}
              handleChoice={onSeletionChange}
              defaultOption={defaultSelection}
              maxWidth="120rem"
            />
          )}
        </div>
      </Tooltip>
      <Tooltip
        placement="top"
        enterDelay={1000}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        arrow
        title="Go to Edit page for this document."
      >
        <Button
          variant="contained"
          sx={{
            fontSize: '1.5rem',
            width: '25rem',
            height: '3rem',
            color: 'white',
            backgroundColor: mainColors.mainBlue,
            borderRadius: '0.2rem',
            padding: '0.2rem',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: mainColors.mainBlue_lighter,
            },
            fontWeight: 600,
            textTransform: 'none',
          }}
          disableElevation={true}
          onClick={() =>
            history.push(
              `editor?fundId=${fundId}&isin=${isin}&documentLanguage=${documentLanguage}`,
            )
          }
          endIcon={
            <EditIcon
              sx={{
                padding: '0.2rem',
                fontSize: '3rem',
                width: '2.5rem',
                height: '2.5rem',
              }}
            />
          }
        >
          Edit Document
        </Button>
      </Tooltip>
    </div>
  );
};

export default ReviewDocumentsButtons;
