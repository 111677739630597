import { ColumnDef } from '@tanstack/react-table';
import { ColumnData } from '../../../../tables/ultraTable/types/column.types';
import { createDefaultColumn } from '../../../../tables/ultraTable/utils/ultratable.utils';
import { GeneralFunctionType } from '../../../../tables/ultraTable/types/functions.types';
import {
  EditableCellWrapper,
  EditableFields,
  EditableFieldsProperties,
} from '../../../../tables/ultraTable/components/EditableCell';
import { DefaultColumn } from '../PositionsUpdatePortal.types';

const SIZE = (window.innerWidth - 100) / 8;
const MIN_SIZE = 100;

export interface PotentialHedgePosition extends EditableFields {
  position_id: string;
  gm_name: string;
  gm_name_long: string;
  fund_name: string;
  fund_id: string;
  expiry_date: string;
  mr_position: number;
  asset_subclass_name: string;
  instrument_id: number;
  instrument_type_id: number;
  is_etc: boolean;
  adepa_derivative_hedge: 'yes' | 'no' | 'loading' | 'error';
  market_price: number;
  is_coco?: 'yes' | 'no' | 'loading' | 'error';
  isin: string;
  editable_fields: EditableFieldsProperties[];

  // Testing the additional fields from data
  client_price: number;
  cash_type: string;
  country_code: string;
  instrument: string;
  source: string;
}

export const buildPotentialHedgePositionData = (originalData: any) => {
  const builtData: PotentialHedgePosition[] = [];
  originalData.positions.forEach((position: any) => {
    builtData.push({
      ...position,
    });
  });
  return builtData;
};

export const updateColumnTitles = (
  defaultColumns: DefaultColumn[],
  columns: ColumnData[],
): ColumnData[] => {
  return columns.map((column) => {
    const matchingDefaultColumn = defaultColumns.find(
      (defaultColumn) => defaultColumn.column_key === column.id,
    );

    if (matchingDefaultColumn) {
      return {
        ...column,
        title: matchingDefaultColumn.column_name,
      };
    }

    return column;
  });
};

export const hedgeDerivativesColumns: ColumnData[] = [
  {
    id: 'gm_name',
    title: 'GM Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_name_long',
    title: 'General Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'asset_subclass_name',
    title: 'Asset Subclass',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'expiry_date',
    title: 'Expiry Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'mr_position',
    title: 'Position Size',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'fund_name',
    title: 'Fund Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'fund_id',
    title: 'Fund ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'adepa_derivative_hedge',
    title: 'Is Hedge?',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'instrument_id',
    title: 'Instrument ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'market_price',
    title: 'Market Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  // {
  //   field: 'is_coco',
  //   title: 'Is CoCo',
  //   minSize: MIN_SIZE,
  //   size: SIZE,
  //   filterFn: 'arrIncludesSome',
  //   filterVariant: 'select',
  // },
  {
    id: 'is_etc',
    title: 'Is Listed',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },

  // Testing the additional fields from data
  {
    id: 'client_price',
    title: 'Client Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'cash_type',
    title: 'Cash Type',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'country_code',
    title: 'Country Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'instrument',
    title: 'Instrument',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'source',
    title: 'Source',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'isin',
    title: 'ISIN',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_hybrid',
    title: 'Position ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
];

export const buildHedgeDerivativeColumns = (
  columns: ColumnData[],
  editFn: GeneralFunctionType,
): ColumnDef<PotentialHedgePosition>[] => {
  const cols: ColumnDef<PotentialHedgePosition>[] = [];

  cols.push({
    id: 'index',
    size: 60,
    enableResizing: false,
    enableColumnFilter: false,
    enablePinning: true,
    header: ({ table }) => (
      <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
    ),
    cell: ({ table, row }) => {
      const filteredRowIndex =
        table.getFilteredRowModel().rows.findIndex((r) => r.id === row.id) + 1;

      return <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>;
    },
  });

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.id,
      accessorKey: dc.id,
      cell: ({ getValue, row: { original, index }, column, table, cell }) => {
        return (
          <EditableCellWrapper
            getValue={getValue}
            original={original}
            index={index}
            column={column}
            table={table}
            cell={cell}
            fn={editFn}
          />
        );
      },

      header: dc.title,
      footer: dc.id,
      ...(dc.enableResizing && { enableResizing: dc.enableResizing }),
      ...(dc.enableColumnFilter && {
        enableColumnFilter: dc.enableColumnFilter,
      }),
      minSize: dc.minSize,
      ...(dc.filterFn && { filterFn: dc.filterFn }),
      meta: {
        filterVariant: dc.filterVariant,
        short_title: dc.short_title ? dc.short_title : dc.title,
        editable: dc.editable, // Changed from isEditable to editable
      },
      ...(dc.size && { size: dc.size }),
    });
  });

  return cols;
};
